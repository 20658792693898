<template>
<div>
    <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <lgl-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :RequirementEdit="RequirementEdit"></lgl-requirement-edit>
        </v-dialog>
    </div>

    <!-- LISTADO DE REQUERIMEINTOS POR TIPO   -->
    <v-row>
        <v-col style="padding-bottom: 0px">
            <s-crud 
                noFull 
                height="200" 
                ref="grid" 
                search-input="" 
                add-no-function 
                :filter="filter"
                :config="config"
            >
            </s-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
//Services
import _sFiles from "@/services/Legal/LglFiles";

export default {
    name: "RequirementService",
    components: {

    },
    props: {
    },
    data() {
        return {
           
            filter: {
                BeginDate: null,
            },
            config: {
                model: {
                    ReqDocumentNumber: "ID",
                },
                service: _sFiles,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },


                ],
            },
            miHtmlEmail: [],
            dialogHtmlEmail: false,
        }
    },
    created() {
    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },

    },

    methods: {

    },

};
</script>
